import React from 'react';
import { graphql } from 'gatsby';

import HeroTypeC from '../components/Heroes/HeroTypeC';
import BannerInfoTypeB from '../components/Banners/BannerInfoTypeB';
import TripleBanner from '../components/Location/TripleBanner';
import BannerTypeA from '../components/Banners/BannerTypeA';
import BannerInfoTypeA from '../components/Banners/BannerInfoTypeA';
import MultiArticleV2 from '../components/Articles/MultiArticleV2';
import MultiArticle from '../components/Articles/MultiArticle';

export default function Barrie({ data }) {
  const hero_type_c = data.strapiBarrieAbout.Hero;
  const info_banner_type_b = data.strapiBarrieAbout.Info_Banner_Type_B;
  const triple_banner = data.strapiBarrieAbout.Extra_Info_Banner;
  const banner_type_middle = data.strapiBarrieAbout.Blog_Banner_Middle;
  const info_banner_type_a = data.strapiBarrieAbout.Info_Banner_Type_A;
  const multi_article_content = data.strapiBarrieAbout.Mutli_Articles_Section;

  return (
    <>
      <HeroTypeC
        title={hero_type_c.Title}
        subtitle={hero_type_c.Content}
        heroImage={
          hero_type_c.BackgroundImage.localFile.childImageSharp.gatsbyImageData
        }
      />
      <BannerInfoTypeB
        description={info_banner_type_b.Content}
        bgImage={
          info_banner_type_b.BackgroundImage.localFile.childImageSharp
            .gatsbyImageData
        }
        buttonLabel={info_banner_type_b.Button.Label}
        buttonLink={info_banner_type_b.Button.Custom_Link_Slug}
        center
      />
      <TripleBanner
        title={triple_banner.Title}
        banners={triple_banner.Info_Banners}
      />
      <BannerTypeA
        title={banner_type_middle.Title}
        description={banner_type_middle.Content}
        bannerImage={
          banner_type_middle.Background_Image.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/barrie/delivery"
        buttonLabel="LEARN ABOUT DELIVERY"
      />
      <BannerInfoTypeA
        title={info_banner_type_a.Title}
        description={info_banner_type_a.Content}
        bgImage={
          info_banner_type_a.Image.localFile.childImageSharp.gatsbyImageData
        }
      />
      <MultiArticleV2
        leftarticle={multi_article_content.Left_Mutli_Article}
        rightarticle={multi_article_content.Right_Multi_Article}
      />
    </>
  );
}

export const query = graphql`
  query {
    strapiBarrieAbout {
      Hero {
        Content
        Title
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Info_Banner_Type_B {
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        Button {
          Custom_Link_Slug
          Label
        }
        Content
      }
      Extra_Info_Banner {
        Sub_Title
        Title
        Info_Banners {
          Content
          Header
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      Info_Banner_Type_A {
        Title
        Content
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Blog_Banner_Middle {
        Content
        Title
        Blog_Button_Link {
          Button_Label
          Link_Slug
        }
        Background_Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Mutli_Articles_Section {
        Left_Mutli_Article {
          article {
            Title
            slug
            Preview_Text
            BannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        Right_Multi_Article {
          article {
            Title
            Preview_Text
            slug
            BannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }

    allStrapiArticles {
      edges {
        node {
          id
          Title
          slug
          Preview_Text
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
